import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Description, DescriptionType } from './interfaces/descriptions.interface';

@Injectable()
export class DescriptionsService {
    getDescriptions(hotelId: string): Observable<Description[]> {
        return of([]);
    }

    getDescription(hotelId: string, type: DescriptionType, id: number): Observable<Description> {
        return of(undefined);
    }

    updateDescription(hotelId: string, description: Description): Observable<void> {
        return of();
    }
}
