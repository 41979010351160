import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { EndpointService } from '../endpoint.service';

import { HotelSummary } from './interfaces/hotel-summary.interface';
import { HotelSummaryServiceInterface } from './interfaces/hotel-summary.service.interface';

@Injectable()
export class HotelSummaryService extends EndpointService implements HotelSummaryServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    getHotelSummary(hotelId: string, useCache: boolean = true): Observable<HotelSummary> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotel/json/hotelSummary.json`;
        const url = UrlHelpers.buildUrl(baseUrl, {
            hotelId,
            useCache,
        });

        return this.httpGet(url, EndpointService.HTTP_HEADERS);
    }
}
