import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RouteHelpers } from '@bmng/helpers/route-helpers';
import { UsersService } from '@bmng/services/users/users.service';
import { MomentHelpers, NumeralService, SessionStorageService } from '@kognitiv/bm-components';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EndpointService } from './../endpoint.service';
import { ContextObjectResponse } from './interfaces/context-object-response.interface';
import { ContextParams } from './interfaces/context-params.interface';
import { ContextServiceInterface } from './interfaces/context-service.interface';
import { ContextType } from './interfaces/context-type.type';

export type ContextRole = 'admin' | 'updater' | 'receptionist' | 'uabadmin' | 'uabuser'
    | 'viewer' | 'webdeveloper' | 'webdeveloperDS' | 'apiAdmin';

@Injectable()
export class ContextService extends EndpointService implements ContextServiceInterface {
    private static readonly FAKE_ROLE_KEY_NAME = 'faked_role';

    public context: BehaviorSubject<ContextObjectResponse> = new BehaviorSubject({
        context: undefined,
        menu: [],
        rights: {},
        contextInformation: {
            languages: [],
            defaultLanguage: undefined,
            defaultCurrency: undefined,
            defaultTimezone: undefined,
        },
        globalRoles: [],
    });
    public baseContextUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private _superAdminRole = 'CM_SUPER_USER';
    private _superViewerRole = 'CM_SUPER_VIEWER';
    private _partnerAdminRole = 'CM_PARTNER_ADMIN';
    private _uabAdminRole = 'uabadmin';

    private get fakedRole(): ContextRole {
        const role = this.sessionStorageSrv.getItem(ContextService.FAKE_ROLE_KEY_NAME) as ContextRole;
        return role ? role : null;
    }

    static getContextParams(route: ActivatedRouteSnapshot): ContextParams {
        const params = RouteHelpers.getFullParamsFromRoute(route);
        const data = RouteHelpers.getFullDataFromRoute(route);
        const typeToken = data.contextType ? data.contextType : 'global';

        return {
            id: params.contextId,
            type: this.sanitizeContextType(typeToken),
            hotelId: params.hotelId,
            unitId: params.unitId,
        };
    }

    static sanitizeContextType(token: string): ContextType {
        const knownSubstitutes: { [key: string]: ContextType } = {
            cpcchain: 'cpc',
        };

        const hasSubstitute = !!knownSubstitutes[token];

        if (hasSubstitute) {
            return knownSubstitutes[token];
        }

        const validTypeValues: string[] = [ 'global', 'portal', 'master', 'pms', 'unit', 'hotel', 'cpc', 'circle', 'direct' ];

        if (!validTypeValues.includes(token)) {
            const message = `Context Type value "${token}" is not valid. Valid context types are: ${validTypeValues.join()}`;

            if (!!console.warn) {
                console.warn(message);
            } else {
                console.log(message);
            }
        }

        return <ContextType>token;
    }

    constructor(
        http: HttpClient,
        private usersService: UsersService,
        private sessionStorageSrv: SessionStorageService,
        private router: Router,
    ) {
        super(http);
    }

    fakeRole(role: ContextRole): void {
        this.sessionStorageSrv.setItem(ContextService.FAKE_ROLE_KEY_NAME, role);
    }

    resetFakeRole(): void {
        this.sessionStorageSrv.removeItem(ContextService.FAKE_ROLE_KEY_NAME);
    }

    isChannel(route: ActivatedRouteSnapshot): boolean {
        const data = RouteHelpers.getFullDataFromRoute(route);
        return !!data.contextType ? data.contextType === 'master' : false;
    }

    generateUrlFromContextParams(params: ContextParams, route: ActivatedRouteSnapshot): UrlTree {
        const urlPaths: string[] = [];
        let node = route.root;
        do {
            urlPaths.push(node.routeConfig?.path);

            node = node.firstChild;
        } while (node);

        const existingParams = RouteHelpers.getFullParamsFromRoute(route);
        existingParams.contextId = params.id;
        existingParams.unitId = params.unitId;
        existingParams.hotelId = params.hotelId;

        let fullPath = urlPaths.filter(p => p).join('/');
        Object.entries(existingParams).forEach(([ key, value ]) => {
            fullPath = fullPath.replace(new RegExp(`:${key}`), value);
        });

        return this.router.createUrlTree([ fullPath ], {
            queryParams: route.root.queryParams,
            fragment: route.root.fragment,
        });
    }

    getContext(route: ActivatedRouteSnapshot): Observable<ContextObjectResponse> {
        const contextParam = ContextService.getContextParams(route);
        this.baseContextUrl.next(this.getBaseContextUrl(route));

        let url: string = ``;
        const downgradedRole = this.fakedRole;

        if (contextParam.type === 'global') {
            url += `${EndpointService.getBmBackendUrl()}/api/settings/context/${contextParam.type}`;
        } else {
            url += `${EndpointService.getBmBackendUrl()}/api/settings/context/${contextParam.type}/${contextParam.id}`;

            if (contextParam.unitId) {
                url += `/unit/${contextParam.unitId}`;
            } else if (contextParam.hotelId) {
                url += `/hotel/${contextParam.hotelId}`;
            }
        }

        if (!!downgradedRole) {
            url += `?fakeRole=${downgradedRole}`;
        }

        const headers = {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
        };

        return this.httpGet<ContextObjectResponse>(url, headers).pipe(
            tap((resp: ContextObjectResponse) => {
                const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                const userSettings = this.usersService.currentUserSettings.getValue();
                userSettings.currency = resp.contextInformation.defaultCurrency || userSettings.currency;
                userSettings.timeZone = resp.contextInformation.defaultTimezone || userSettings.timeZone || browserTimezone;
                // TODO: set language as per the default settings provided by the context service
                // userSettings.language = resp.contextInformation.defaultLanguage || userSettings.language;

                NumeralService.configure(userSettings);
                MomentHelpers.setTimezone(userSettings.timeZone);
            }),
            tap((resp: ContextObjectResponse) => this.context.next(resp)),
        );
    }

    isSuperAdmin(): boolean {
        return this.hasGlobalRole(this._superAdminRole);
    }
    isSuperViewer(): boolean {
        return this.hasGlobalRole(this._superViewerRole);
    }
    isPartnerAdmin(): boolean {
        return this.hasGlobalRole(this._partnerAdminRole) || this.hasGlobalRole(this._uabAdminRole);
    }
    isSuperAdminOrViewer(): boolean {
        return this.isSuperAdmin() || this.isSuperViewer();
    }
    hasRight(neededComponent: string, neededRight: 'read' | 'write'): boolean {
        const currentRights = this.context.getValue().rights;
        if (Object.keys(currentRights).indexOf(neededComponent) === -1) {
            return false;
        }

        const right = currentRights[neededComponent];
        return !!right[neededRight];
    }

    getCurrentRole(): string {
        if (!!this.fakedRole && this.isSuperAdmin()) {
            return this.fakedRole;
        }

        const fullContext = this.context.value;
        const activeContext = RouteHelpers.getActiveContext(fullContext.context);

        if (activeContext.role === 'CM_SUPER_USER' || fullContext.globalRoles.includes('CM_SUPER_USER')) {
            return 'superadmin';
        } else if (activeContext.role === 'CM_SUPER_VIEWER' || fullContext.globalRoles.includes('CM_SUPER_VIEWER')) {
            return 'superviewer';
        } else if (activeContext.role) {
            return activeContext.role;
        }

        return '';
    }

    getContextForMenu(snapshot: ActivatedRouteSnapshot): { context: Exclude<ContextType, 'pms'>; id: string } {
        const params = RouteHelpers.getFullParamsFromRoute(snapshot);
        const data = RouteHelpers.getFullDataFromRoute(snapshot);

        const contextType = data.contextType ? data.contextType : 'global';
        if ((contextType === 'portal' || contextType === 'master') && params.unitId) {
            return { context: 'unit', id: params.unitId };
        }
        return { context: contextType, id: params.contextId };
    }

    private hasGlobalRole(role: string): boolean {
        return this._getRoles().indexOf(role) !== -1 ||
            this.context.getValue().globalRoles.indexOf(role) !== -1;
    }

    private _getRoles(): string[] {
        const currentContext = this.context.getValue().context;
        return Object.keys(currentContext)
            .map(key => currentContext[key])
            .map(ctx => ctx.role)
            .filter(role => !!role);
    }

    public getBaseContextUrl(route: ActivatedRouteSnapshot): string {
        const routeData = RouteHelpers.getFullDataFromRoute(route);
        const routeParams = RouteHelpers.getFullParamsFromRoute(route);

        const contextType = routeData.contextType ? routeData.contextType : 'global';

        if (contextType === 'global') {
            return '';
        }

        if (contextType === 'hotel') {
            // Special case with empty master
            return `master/hotel/${routeParams.contextId}`;
        }

        if (contextType === 'direct') {
            return `circle/${routeParams.contextId}`;
        }

        const routeParts = [ contextType, routeParams.contextId ];

        if (routeParams.unitId) {
            routeParts.push('unit', routeParams.unitId);
        }
        if (routeParams.hotelId) {
            routeParts.push('hotel', routeParams.hotelId);
        }

        return routeParts.join('/');
    }
}
