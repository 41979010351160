import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AnalyticsService } from '@bmng/services/analytics/analytics.service';
import { AssetService } from '@bmng/services/asset/asset.service';
import { ChainPropertiesEnabledService } from '@bmng/services/chain-properties-enabled/chain-properties-enabled.service';
import { ErrataService } from '@bmng/services/errata/errata.service';
import { HotelCpcService } from '@bmng/services/hotel-cpc/hotel-cpc.service';
import { I18nService } from '@bmng/services/i18n.service';
import { ImagesService } from '@bmng/services/images/images.service';
import { PromotionsService } from '@bmng/services/promotions/promotions.service';
import { PropertyService } from '@bmng/services/property/property.service';
import { RateRestrictionsService } from '@bmng/services/rate-restrictions/rate-restrictions.service';
import { RateServicesService } from '@bmng/services/rate-services/rate-services.service';
import { SearchAutocompleteService } from '@bmng/services/search-autocomplete/search-autocomplete.service';
import { TrackingService } from '@bmng/services/tracking/tracking.service';
import { VouchersService } from '@bmng/services/vouchers/vouchers.service';
import { LangModule } from '@kognitiv/angular-i18n';
import { BmComponentsModule, TimePipe } from '@kognitiv/bm-components';

import { AccessRestrictionSummaryService } from './services/access-restrictions/access-restriction-summary.service';
import { AccessRestrictionsService } from './services/access-restrictions/access-restrictions.service';
import { AutocompleteService } from './services/autocomplete/autocomplete.service';
import { AwardsService } from './services/awards/awards.service';
import { AwardsServiceMock } from './services/awards/awards.service.mock';
import { BmLoginService } from './services/bm-login/bm-login.service';
import { BookabilityService } from './services/bookability/bookability.service';
import { BookingDataService } from './services/booking-data/booking-data.service';
import { BookingStylesService } from './services/booking-styles/booking-styles.service';
import { ChannelAttributesService } from './services/channel-attributes/channel-attributes.service';
import { ChannelIdVerificationService } from './services/channel-id-verification/channel-id-verification.service';
import { ChannelIdVerificationServiceMock } from './services/channel-id-verification/channel-id-verification.service.mock';
import { ChannelsService } from './services/channels/channels.service';
import { ClipboardService } from './services/clipboard.service';
import { ContactSupportService } from './services/contact-support/contact-support.service';
import { ContextService } from './services/context/context.service';
import { ContractsService } from './services/contracts/contracts.service';
import { CpcPaymentService } from './services/cpc-payments/cpc-payments.service';
import { CpcStatusService } from './services/cpc-status/cpc-status.service';
import { CpcChainUnitInfoService } from './services/cpcchain-unitinfo/cpcchain-unitinfo.service';
import { DerivedRateRulesPersistanceService } from './services/derived-rates/derived-rate-rules-persistance.service';
import { DerivedRatesService } from './services/derived-rates/derived-rates.service';
import { DescriptionsService } from './services/descriptions/descriptions.service';
import { DescriptionsServiceMock } from './services/descriptions/descriptions.service.mock';
import { DynamicAllocationsService } from './services/dynamic-allocations/dynamic-allocations.service';
import { EntitySelectionService } from './services/entity-selection/entity-selection.service';
import { ErrorHandlerService } from './services/error-handler/error-handler.service';
import { GuestNotificationCustomService } from './services/guest-notification-custom/guest-notification-custom.service';
import { GuestNotificationStyleService } from './services/guest-notification-style/guest-notification-style.service';
import { GuestNotificationsService } from './services/guest-notifications/guest-notifications.service';
import { GuestNotificationsServiceMock } from './services/guest-notifications/guest-notifications.service.mock';
import { HotelErpService } from './services/hotel-erp/hotel-erp.service';
import { HotelSearchService } from './services/hotel-search/hotel-search.service';
import { HotelSummaryService } from './services/hotel-summary/hotel-summary.service';
import { KubeBootstrapService } from './services/kube-bootstrap/kube-bootstrap.service';
import { KubeWidgetsService } from './services/kube-widgets/kube-widgets.service';
import { LabelOverwritesService } from './services/label-overwrites/label-overwrites.service';
import { LinkService } from './services/link.service';
import { MappingService } from './services/mapping/mapping.service';
import { MenuService } from './services/menu/menu.service';
import { MetadataService } from './services/metadata/metadata.service';
import { ModalViewTypeService } from './services/modals/modal-view-type.service';
import { StackableRoutingNavigatorService } from './services/navigation/stackable-routing-navigator.service';
import { PageService } from './services/page.service';
import { PmsService } from './services/pms/pms.service';
import { PoliciesUtilService } from './services/policies/policies-util.service';
import { PoliciesService } from './services/policies/policies.service';
import { RoomRateAugmentationService } from './services/power-grid/room-rate-augmentation.service';
import { PriceDropAlertService } from './services/price-drop-alerts/price-drop-alerts.service';
import { RatePlanSetupService } from './services/rates/rate-plan-setup.service';
import { RatesService } from './services/rates/rates.service';
import { RecentSelectionService } from './services/recent-selection/recent-selection.service';
import { ReportsService } from './services/reports/reports.service';
import { ReservationPaymentLinkService } from './services/reservation-payment-link/reservation-payment-link.service';
import { ReservationPaymentService } from './services/reservation-payment/reservation-payment.service';
import { ReservationsMisconductService } from './services/reservations-misconduct/reservations-misconduct.service';
import { ReservationsService } from './services/reservations/reservations.service';
import { ComposableRoomDependencyResolverService } from './services/room-types/composable-room-dependency-resolver.service';
import { RouteTransformerService } from './services/routes/route-transformer.service';
import { StaticInfoService } from './services/static-info/static-info.service';
import { TableIdGeneratorService } from './services/table-id-generator.service';
import { TableRendererService } from './services/table-renderer.service';
import { UsersService } from './services/users/users.service';
import { WaitingListService } from './services/waiting-list/waiting-list.service';
import { AirbnbService } from './services/wrappers/airbnb/airbnb.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BmComponentsModule.forRoot(),
        LangModule.forRoot(),
    ],
    declarations: [],
    exports: [],
    providers: [
        Title,
        TableIdGeneratorService,
        TableRendererService,

        // Services
        PageService,
        UsersService,
        RatesService,
        ContextService,
        MenuService,
        LinkService,
        I18nService,
        AnalyticsService,
        RouteTransformerService,
        TrackingService,
        StackableRoutingNavigatorService,
        ClipboardService,

        AssetService,
        PmsService,
        ChannelsService,
        ChannelAttributesService,
        MetadataService,
        StaticInfoService,
        BookabilityService,
        BookingStylesService,
        CpcStatusService,
        CpcPaymentService,
        HotelErpService,
        HotelCpcService,
        HotelSearchService,
        ReservationsService,
        ReservationPaymentService,
        ReservationPaymentLinkService,
        ReservationsMisconductService,
        RateServicesService,
        PriceDropAlertService,
        BmLoginService,
        EntitySelectionService,
        RecentSelectionService,
        PropertyService,
        PoliciesService,
        PoliciesUtilService,
        AccessRestrictionSummaryService,
        AccessRestrictionsService,
        RatePlanSetupService,
        BookingDataService,
        RoomRateAugmentationService,

        GuestNotificationCustomService,
        {
            provide: GuestNotificationsService,
            useClass: GuestNotificationsServiceMock,
        },
        GuestNotificationStyleService,
        ImagesService,
        {
            provide: ChannelIdVerificationService,
            useClass: ChannelIdVerificationServiceMock,
        },
        {
            provide: DescriptionsService,
            useClass: DescriptionsServiceMock,
        },

        DynamicAllocationsService,
        AirbnbService,

        RateRestrictionsService,

        ErrataService,

        ContractsService,
        AutocompleteService,

        PromotionsService,
        ReportsService,
        VouchersService,
        WaitingListService,
        SearchAutocompleteService,
        {
            provide: AwardsService,
            useClass: AwardsServiceMock,
        },
        ChainPropertiesEnabledService,
        CpcChainUnitInfoService,
        ContactSupportService,
        DerivedRatesService,
        KubeWidgetsService,
        KubeBootstrapService,

        ErrorHandlerService,

        DerivedRateRulesPersistanceService,

        ModalViewTypeService,
        ComposableRoomDependencyResolverService,
        LabelOverwritesService,
        MappingService,
        HotelSummaryService,

        // TODO Move to component lib
        TimePipe,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only!');
        }
    }
}
