import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { AccessRestriction, AccessRestrictionRule } from './interfaces/access-restriction.interface';
import { AccessRestrictionsServiceInterface } from './interfaces/access-restrictions.service.interface';

@Injectable()
export class AccessRestrictionsService extends EndpointService implements AccessRestrictionsServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    public getAccessRestrictions(hotelId: string): Observable<AccessRestriction[]> {
        const url = this.getAccessRatesUrl(hotelId);
        return this.httpGet<AccessRestriction[]>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    public deleteAccessRestriction(hotelId: string, restrictionCode: number): Observable<boolean> {
        const url = `${EndpointService.getBmBackendUrl()}/api/rateaccess/hotel/${hotelId}/${restrictionCode}`;
        return this.httpDelete(url, EndpointService.HTTP_HEADERS);
    }

    private getAccessRatesUrl(hotelId: string, accessRestrictionCode: number = null): string {
        const restrictionId = (accessRestrictionCode === null) ? '' : `/${accessRestrictionCode}`;
        return `${EndpointService.getBmBackendUrl()}/api/rateaccess/hotel/${hotelId}${restrictionId}`;
    }

    public getAccessRestriction(hotelId: string, accessRestrictionCode: number): Observable<AccessRestriction> {
        const url = this.getAccessRatesUrl(hotelId, accessRestrictionCode);
        return this.httpGet<AccessRestriction>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    public getDefaultAccessRestriction(): AccessRestriction {
        return {
            acrossHotels: false,
            code: null,
            changed: true,
            defaultAllow: false,
            exclusive: false,
            rules: [
                {
                    type: 'Allow',
                    includedChannels: [],
                    promotionCodes: [],
                    includedStayDates: [],
                    excludedStayDates: [],
                    rateFence: {
                        includedCountryCodes: [],
                        excludedCountryCodes: [],
                        includedFenceDates: [],
                        excludedFenceDates: [],
                        applyToWeekdays: [],
                        deviceTypes: [],
                        timeFences: [],
                    },
                },
            ],
            internalNote: '',
            usedBy: [],
            obsolete: false,
            chainOnly: null,
            hotelId: '',
        };
    }

    public updateAccessRestriction(hotelId: string, accessRestriction: AccessRestriction): Observable<AccessRestriction> {
        const restrictionCode = !!accessRestriction.code ? accessRestriction.code : null;
        const url = this.getAccessRatesUrl(hotelId);

        if (restrictionCode !== null) {
            return this.httpPut(url, accessRestriction, EndpointService.HTTP_HEADERS);
        } else {
            return this.httpPost(url, accessRestriction, EndpointService.HTTP_HEADERS);
        }
    }

    public getDefaultAccessRestrictionRule(): AccessRestrictionRule {
        return {
            type: 'Allow',
            includedChannels: [],
            promotionCodes: [],
            includedStayDates: [],
            excludedStayDates: [],
            rateFence: {
                includedCountryCodes: [],
                excludedCountryCodes: [],
                includedFenceDates: [],
                excludedFenceDates: [],
                applyToWeekdays: [],
                deviceTypes: [],
                timeFences: [],
            },
        };
    }
}
