import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { LangService } from '@kognitiv/angular-i18n';
import { DataProviderConfig, SortDirectionType } from '@kognitiv/bm-components';
import { Observable } from 'rxjs';

import { ContextType } from '../context/interfaces/context-type.type';
import { EndpointData } from '../endpoint-data.interface';
import { UsersService } from '../users/users.service';
import { environment } from './../../../../environments/environment';
import { EndpointService } from './../endpoint.service';
import { CreditCardInfo } from './interfaces/reservations.creditcard.interface';
import {
    AcceptOrRejectReservationStatus,
    CancelledReservationStatus,
    NoShowReservationStatus,
    ReservationBooleanResult,
    ReservationChangeStatusResult,
    ReservationDetail,
    ReservationRoomsToModify,
    ReservationsResult,
    ResRoomGuests,
    ResService,
    ResVoucher,
} from './interfaces/reservations.interface';
import { ReservationsServiceInterface } from './interfaces/reservations.service.interface';

@Injectable()
export class ReservationsService extends EndpointService implements ReservationsServiceInterface {
    constructor(
        http: HttpClient,
        private readonly usersService: UsersService,
        private readonly lang: LangService,
    ) {
        super(http);
    }

    getReservations(
        entityId: string,
        contextType: ContextType,
        query: DataProviderConfig,
    ): Observable<ReservationsResult> {
        const additionalParams = {
            keyword: query.searchTerm,
        };
        this.addPaging(additionalParams, query);

        const url = this._getSearchUrl('json', entityId, contextType, query, additionalParams);

        return this.httpGet<ReservationsResult>(url, EndpointService.HTTP_HEADERS);
    }

    downloadReservations(
        entityId: string,
        contextType: ContextType,
        query: DataProviderConfig,
        withComments: boolean,
    ): void {
        const additionalParams: { [key: string]: string | boolean } = {};

        if (withComments) {
            additionalParams.includeComments = true;
        }

        const url = this._getSearchUrl('xlsx', entityId, contextType, query, additionalParams);

        window.open(url);
    }

    getReservationDetail(
        hotelId: string,
        reservationId: string,
        channelId: string,
        contextType: ContextType,
    ): Observable<ReservationDetail> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/modify`;
        const params: { [key: string]: string | boolean } = {
            hotelId,
            reservationId,
            languageCode: this.lang.getCurrentLanguage(),
        };

        if (channelId) {
            params.channelId = channelId;
        }

        if (contextType === 'global') {
            params.global = true;
        }

        return this.httpGet<ReservationDetail>(UrlHelpers.buildUrl(url, params), EndpointService.HTTP_HEADERS);
    }

    getModifiedReservationValidation(modifyResData: ReservationDetail): Observable<EndpointData<ReservationDetail>> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/modify/validate`;

        return this.httpPostWithFullData<ReservationDetail>(url, modifyResData, EndpointService.HTTP_HEADERS);
    }

    getAvailableRoomsForModify(
        hotelId: string,
        reservationId: string,
        checkIn: string,
        checkOut: string,
        guests: ResRoomGuests,
        roomCode?: string,
        ratePlanCode?: string,
        voucherCode?: string,
    ): Observable<ReservationRoomsToModify> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/modify/available/rooms`;

        const params: { [key: string]: unknown } = {
            hotelId,
            reservationId,
            languageCode: this.lang.getCurrentLanguage(),
            checkIn,
            checkOut,
            adults: guests.adults,
        };

        if (guests.children) {
            params.children = guests.children;
        }

        if (guests.childAges && guests.childAges.length) {
            params.childAges = guests.childAges;
        }
        if (roomCode) {
            params.roomCode = roomCode;
        }
        if (ratePlanCode) {
            params.ratePlanCode = ratePlanCode;
        }
        if (voucherCode) {
            params.voucherCode = voucherCode;
        }

        return this.httpGet<ReservationRoomsToModify>(UrlHelpers.buildUrl(url, params), EndpointService.HTTP_HEADERS);
    }

    getAvailableServicesForModify(
        hotelId: string,
        reservationId: string,
        checkIn: string,
        checkOut: string,
    ): Observable<ResService[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/modify/available/services`;
        const params = {
            hotelId,
            reservationId,
            languageCode: this.lang.getCurrentLanguage(),
            checkIn,
            checkOut,
        };

        return this.httpGet<ResService[]>(UrlHelpers.buildUrl(url, params), EndpointService.HTTP_HEADERS);
    }

    getAvailableVouchersForModify(resData: ReservationDetail): Observable<ResVoucher[]> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/modify/available/vouchers`;

        return this.httpPost<ResVoucher[]>(url, resData, EndpointService.HTTP_HEADERS);
    }

    confirmTransaction(
        hotelId: string,
        transactionId: string | number,
        merchantId: string | number,
        paymentProvider: string,
    ): Observable<ReservationBooleanResult> {
        const payload = {
            propertyId: hotelId,
            transactionId,
            merchantId,
            paymentProvider,
        };

        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/latest/json/clearTransaction.json`;

        return this.httpPost<ReservationBooleanResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    confirmPayment(
        hotelId: string,
        reservationId: string,
        comment: string,
    ): Observable<ReservationBooleanResult> {
        const payload = {
            propertyId: hotelId,
            reservation_id: reservationId,
            comment,
            status: 'Reserved',
        };

        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/latest/json/reservationModify.json`;

        return this.httpPost<ReservationBooleanResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    acceptOrRejectReservation(
        hotelId: string,
        reservationId: string,
        comment: string,
        status: AcceptOrRejectReservationStatus,
    ): Observable<ReservationChangeStatusResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/latest/json/reservationModify.json`;

        const payload = {
            propertyId: hotelId,
            reservation_id: reservationId,
            comment,
            status,
        };

        return this.httpPost<ReservationChangeStatusResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    cancelReservation(
        hotelId: string,
        reservationId: string,
        comment: string,
        refundAmount: number | null,
        portalId?: string,
    ): Observable<ReservationChangeStatusResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/reservationModify.json`;

        const status: CancelledReservationStatus = 'Cancelled';
        const payload: { [key: string]: string | number } = {
            propertyId: hotelId,
            reservation_id: reservationId,
            comment,
            refund_amount: refundAmount,
            status,
        };

        if (portalId) {
            payload.portalId = portalId;
        }

        return this.httpPost<ReservationChangeStatusResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    noshowReservation(
        hotelId: string,
        reservationId: string,
        comment: string,
    ): Observable<ReservationChangeStatusResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/latest/json/reservationModify.json`;

        const status: NoShowReservationStatus = 'NoShow';
        const payload = {
            propertyId: hotelId,
            reservation_id: reservationId,
            comment,
            status,
        };

        return this.httpPost<ReservationChangeStatusResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    getCreditCardInfo(hotelId: string, channelId: string, reservationId: string): Observable<CreditCardInfo> {
        const baseUrl = `${EndpointService.getBmBackendUrl()}/api/hotel/reservation/credit_card_details`;

        const url = UrlHelpers.buildUrl(baseUrl, {
            hotelId,
            reservationId,
            channelId: channelId ?? undefined,
            language: this.lang.getCurrentLanguage().toLowerCase(),
        });

        return this.httpGet<CreditCardInfo>(url, EndpointService.HTTP_HEADERS);
    }

    saveModifiedReservation(modifyResData: ReservationDetail): Observable<ReservationDetail> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/modify/submit`;

        return this.httpPost<ReservationDetail>(url, modifyResData, EndpointService.HTTP_HEADERS);
    }

    saveConfirmationReservation(hotelId: string, reservationId: string, foreignIds: string[]): Observable<ReservationChangeStatusResult> {
        const url = `${EndpointService.getBmBackendUrl()}/api/reservations/modify/id/submit`;
        const payload = {
            hotelId,
            reservationId,
            foreignIds,
            languageCode: 'EN',
        };

        return this.httpPost<ReservationChangeStatusResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    resendConfirmation(hotelId: string, reservationId: string): Observable<ReservationChangeStatusResult> {
        const url = `${environment.baseUrls.switch}/switch/latest/json/resendReservationMail.json`;

        const payload = {
            propertyId: hotelId,
            reservation_id: reservationId,
        };

        return this.httpPost<ReservationChangeStatusResult>(url, payload, EndpointService.HTTP_HEADERS);
    }

    _getSearchUrl(
        fileSuffix: 'json' | 'xlsx',
        entityId: string,
        contextType: ContextType,
        query: DataProviderConfig,
        additionalParams: { [key: string]: unknown },
    ): string {
        let baseUrl = `${EndpointService.getBmBackendUrl()}/api/reservations/`;
        let params: { [key: string]: unknown } = {
            ...additionalParams,
            languageCode: this.lang.getCurrentLanguage(),
        };

        if (contextType !== 'global') {
            const propertyType: string = contextType === 'hotel' ? 'hotel' : 'channel';
            baseUrl += `${propertyType}/${entityId}/`;
        } else {
            params.global = true;
        }

        baseUrl += `search.${fileSuffix}`;

        const userCurrencyCode = this.usersService.currentUserSettings.getValue().currency;
        if (userCurrencyCode && fileSuffix !== 'xlsx') {
            params.currencyCode = userCurrencyCode;
        }

        this.applySorting(params, query);
        params = Object.assign(params, query.filters);

        return UrlHelpers.buildUrl(baseUrl, params);
    }

    // helpers
    private applySorting(params: { [key: string]: unknown }, query: DataProviderConfig): void {
        const sortMap = {
            status: 'STATUS',
            hotelId: 'HOTEL_ID',
            hotelCountry: 'HOTEL_COUNTRY',
            hotelName: 'HOTEL_NAME',
            guestName: 'GUEST_NAME',
            guestEmail: 'EMAIL',
            arrivalDate: 'ARRIVAL_DATE',
            departureDate: 'DEPARTURE_DATE',
            lengthOfStay: 'LENGTH_OF_STAY',
            bookingDate: 'BOOKING_DATE',
            reservedDateTime: 'RESERVED_DATE',
            modifiedDate: 'MODIFIED_DATE',
            onHoldDuration: 'ON_HOLD_DURATION',
            source: 'SOURCE',
            sourceContext: 'SOURCE_CONTEXT',
            guestCountry: 'GUEST_COUNTRY',
            roomPrice: 'ROOM_PRICE',
            servicePrice: 'SERVICE_PRICE',
            totalPrice: 'TOTAL_PRICE',
            discountAmount: 'DISCOUNT_AMOUNT',
            numberOfAdults: 'NUMBER_OF_ADULTS',
            numberOfChildren: 'NUMBER_OF_CHILDREN',
            roomCodes: 'ROOM_CODES',
            ratePlanCodes: 'RATE_CODES',
            paymentType: 'PAYMENT_TYPE',
            serviceCodes: 'SERVICE_CODES',
            packageCodes: 'PACKAGE_CODES',
            promotionCodes: 'PROMOTION_CODES',
            bookedPromotionCodes: 'BOOKED_PROMOTION_CODES',
            userCountry: 'USER_COUNTRY',
            deviceType: 'DEVICE_TYPE',
            foreignIds: 'FOREIGN_IDS',
            voucherCode: 'VOUCHER_CODE',
            voucherDiscount: 'VOUCHER_DISCOUNT',
            voucherDiscountType: 'VOUCHER_DISCOUNT_TYPE',
            campaignDiscountAmount: 'CAMPAIGN_DISCOUNT_AMOUNT',
            invoicePartnerId: 'INVOICE_PARTNER_ID',
            invoicePartnerName: 'INVOICE_PARTNER_NAME',
            netRate: 'NET_RATE',
            itineraryType: 'ITINERARY_TYPE',
            itineraryReservationId: 'ITINERARY_RESERVATION_ID',
            balance: 'BALANCE',
        };

        const sorter = sortMap[query.sortCol];
        if (typeof sorter !== 'undefined') {
            params.sort = sorter;
        }

        const sortDirection: SortDirectionType = query.sortDir;
        if (typeof sortDirection !== 'undefined') {
            params.sortType = sortDirection;
        }
    }

    private addPaging(params: { [key: string]: unknown }, query: DataProviderConfig): void {
        const page: number = (!!query.page && query.page > 0) ? query.page : 0;
        const perPage: number = !!query.resultsPerPage ? query.resultsPerPage : 10;

        params.start = page * perPage;
        params.size = perPage;
    }
}
