import { Component, Injectable, OnInit } from '@angular/core';
import { PdaStatusType } from '@bmng/pages/master/price-drop-alerts-2/types/pda.status.type';
import { LangService } from '@kognitiv/angular-i18n';
import { BadgeColor, RenderComponent } from '@kognitiv/bm-components';

import { StatusDetail } from './status-detail.interface';

@Injectable()
export class PdaStatusHelpers {
    private statusDetails: { [ K in PdaStatusType ]: StatusDetail } = {
        NEW:      { label: this.lang.get('bm.pda.status.NEW'),      color: 'chart-2' },
        OBSOLETE: { label: this.lang.get('bm.pda.status.OBSOLETE'), color: 'secondary' },
        BLOCKED:  { label: this.lang.get('bm.pda.status.BLOCKED'),  color: 'secondary' },
        PENDING:  { label: this.lang.get('bm.pda.status.PENDING'),  color: 'chart-4' },
        OFFERED:  { label: this.lang.get('bm.pda.status.OFFERED'),  color: 'chart-4' },
        DECLINED: { label: this.lang.get('bm.pda.status.DECLINED'), color: 'warning' },
        BOOKED:   { label: this.lang.get('bm.pda.status.BOOKED'),   color: 'success' },
        DISABLED: { label: this.lang.get('bm.pda.status.DISABLED'), color: 'secondary' },
        LIMITED:  { label: this.lang.get('bm.pda.status.LIMITED'),  color: 'warning' },
        REVOKED:  { label: this.lang.get('bm.pda.status.REVOKED'),  color: 'chart-5' },
    };

    constructor(
        private lang: LangService,
    ) {}

    getStatusDetail<K extends keyof StatusDetail>(detailType: K, status: PdaStatusType): StatusDetail[K] {
        const statusDetails = this.getStatusMap(status);

        return statusDetails[detailType];
    }

    getAllStatuses(): PdaStatusType[] {
        return <PdaStatusType[]> Object.keys(this.statusDetails);
    }

    getStatusMap(status: PdaStatusType): StatusDetail {
        return this.statusDetails[status];
    }
}

export interface ObjectWithPossibleStatus {
    status?: PdaStatusType;
}

@Component({
    selector: 'pda-status-badge-renderer',
    template: `
        <span bmBadge [color]="color">{{ label }}</span>
    `,
})
export class PdaStatusBadgeRendererComponent<T extends ObjectWithPossibleStatus> extends RenderComponent<T> implements OnInit {
    data: T;
    label: string;
    color: BadgeColor;

    constructor(
        public statusHelpers: PdaStatusHelpers,
    ) {
        super();
    }

    ngOnInit(): void {
        const status: PdaStatusType = this.data.status;
        if (status) {
            this.label = this.statusHelpers.getStatusDetail('label', status);
            this.color = this.statusHelpers.getStatusDetail('color', status);
        }
    }
}
