import { Injectable } from '@angular/core';
import { DateRangeSingleDatesTranslator } from '@bmng/helpers/date-range-single-dates-translator';
import { CancellationPaymentPolicy, CancellationPenalty, PolicyCancellation, PolicyDateRange, PolicyDescription } from '@bmng/services/policies/interfaces/policies.interface';

import { CancellationRefundType, PolicyContext, UiCancellationPaymentPolicy, UiCancellationPenalty, UiPolicyCancellation } from '../../../../pages/master/policies/interface/policies-ui.interface';

@Injectable()
export class PolicyFormCancellationUiService {
    getUiCancellationPolicyConfig(
        policy: PolicyCancellation,
        context: PolicyContext,
        portalId?: string,
    ): UiPolicyCancellation {
        policy.paymentPolicies = (policy.paymentPolicies || [])
            .sort((a, b) => this.sortByPriority(a, b));

        return {
            type: 'cancellation',
            portalId,
            inherited: policy.portalId !== portalId,
            enabled: policy.enabled,
            sort: policy.sort,
            ...policy,
            cancellationRefund: this.getCancellationRefund(policy),
            uiPaymentPolicies: policy.paymentPolicies.map(paymentPolicy => this.getUICancellationPaymentPolicy(paymentPolicy)),
            context,
        };
    }

    private getTextOnlyDescriptionsFromPaymentPolicy(paymenyPolicy: CancellationPaymentPolicy): PolicyDescription[] {
        if (!paymenyPolicy.penalties || !paymenyPolicy.penalties.length) {
            return [];
        }

        const mainPenalty = paymenyPolicy.penalties[0];
        if (mainPenalty && mainPenalty.descriptions) {
            return mainPenalty.descriptions;
        }

        return [];
    }

    private getCancellationRefund(policy: PolicyCancellation): CancellationRefundType {
        const main = policy.paymentPolicies.find(paymentPolicy => !paymentPolicy.dateRanges);
        if (main && main.penalties && main.penalties.length === 1) {
            const mainPenalty = main.penalties[0];
            const noAmount = typeof mainPenalty.amount !== 'number' || mainPenalty.amount === 0;
            const noNights = typeof mainPenalty.numberOfNights === 'undefined' || mainPenalty.numberOfNights === null;
            const isAlways = this.isAlwaysApplicablePenalty(mainPenalty);

            if (isAlways && noAmount && noNights) {
                if (!mainPenalty.percent) {
                    return 'AlwaysRefundable';
                }
                if (mainPenalty.percent === 100) {
                    return 'AlwaysNonRefundable';
                }
            }
        }

        return 'CustomRefundable';
    }

    getUICancellationPaymentPolicy(paymentPolicy: CancellationPaymentPolicy): UiCancellationPaymentPolicy {
        const hasDates = paymentPolicy.dateRanges && paymentPolicy.dateRanges.length;
        const uiCancellationPaymentPolicy: UiCancellationPaymentPolicy = {
            priority: paymentPolicy.priority,
            uiDateRanges: hasDates ? this.getDatesFromDateRanges(paymentPolicy.dateRanges) : [],
            paymentPolicyType: !hasDates ? 'main' : 'override',
            textOnlyDescriptions: this.getTextOnlyDescriptionsFromPaymentPolicy(paymentPolicy),
            uiPenalties: (paymentPolicy.penalties || []).map(penalty => this.getUiCancellationPenalty(penalty)),
        };

        return uiCancellationPaymentPolicy;
    }

    getUiCancellationPenalty(penalty: CancellationPenalty): UiCancellationPenalty {
        const isAlways = this.isAlwaysApplicablePenalty(penalty);

        return {
            leadTimeType: isAlways ? 'Always' : 'Days',
            leadTimeDays: isAlways ? null : penalty.leadTimeDays,
            leadTimeOfDay: this.getLeadTimeOfDay(penalty.leadTimeOfDay),
            hasPercent: typeof penalty.percent === 'number',
            percent: typeof penalty.percent === 'number' ? penalty.percent : null,
            stayType: typeof penalty.numberOfNights === 'number' ? 'Nights' : 'FullStay',
            numberOfNights: typeof penalty.numberOfNights === 'number' ? penalty.numberOfNights : null,
            hasAmount: typeof penalty.amount === 'number',
            amount: typeof penalty.amount === 'number' ? penalty.amount : null,
            descriptions: penalty.descriptions || [],
            showDescriptions: (penalty.descriptions || []).some(descriptions => descriptions.description),
            summary: penalty.summary,
        };
    }

    private isAlwaysApplicablePenalty(penalty: CancellationPenalty): boolean {
        return penalty.leadTimeAlways === true || (
            (typeof penalty.leadTimeAlways === 'undefined' || penalty.leadTimeAlways === null) &&
            (typeof penalty.leadTimeDays === 'undefined' || penalty.leadTimeDays === null)
        );
    }

    private getLeadTimeOfDay(leadTimeOfDay: string): string {
        if (leadTimeOfDay) {
            if (leadTimeOfDay.length === 8) {
                leadTimeOfDay = leadTimeOfDay.slice(0, 5);
            }
            return leadTimeOfDay;
        }

        return '00:00';
    }

    private getDatesFromDateRanges(dateRanges: PolicyDateRange[]): string[] {
        return DateRangeSingleDatesTranslator.translateToDateTokens(
            dateRanges.map(range => ({
                from: range.start,
                to: range.end,
            })),
        );
    }

    getDefaultCancellationPolicyConfig(currencyCode: string, includeOverrides: boolean = false, portalId?: string): PolicyCancellation {
        const policyConfig: PolicyCancellation = {
            code: 'CANCEL',
            currencyCode,
            onlineCancellationByGuest: 'AllowOnlineCancelIfNoPaymentDue',
            portalId,
            textOnly: false,
            enabled: true,
            paymentPolicies: [
                {
                    priority: 1,
                    penalties: [
                        {
                            leadTimeAlways: true,
                            leadTimeDays: null,
                            leadTimeOfDay: null,
                            percent: null,
                            numberOfNights: null,
                            amount: null,
                            descriptions: [],
                        },
                    ],
                },
            ],
            applicability: {
                hotels: [],
                suppliers: [],
            },
        };

        if (includeOverrides) {
            policyConfig.paymentPolicies.push({
                priority: 2,
                dateRanges: [
                    {
                        start: null,
                        end: null,
                    },
                ],
                penalties: [
                    {
                        leadTimeAlways: true,
                        leadTimeDays: null,
                        leadTimeOfDay: null,
                        numberOfNights: null,
                        amount: null,
                        percent: null,
                        descriptions: [],
                    },
                ],
            });
        }

        return policyConfig;
    }

    getDefaultUiCancellationPolicyConfig(currencyCode: string): UiPolicyCancellation {
        const config = this.getDefaultCancellationPolicyConfig(currencyCode);
        const uiConfig = this.getUiCancellationPolicyConfig(config, null);

        return uiConfig;
    }

    private sortByPriority(a: CancellationPaymentPolicy, b: CancellationPaymentPolicy): number {
        const keyA = a.priority;
        const keyB = b.priority;

        if (keyA < keyB) {
            return -1;
        }
        if (keyA > keyB) {
            return 1;
        }

        return 0;
    }
}
