import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Description, DescriptionType } from './interfaces/descriptions.interface';

@Injectable()
export class DescriptionsServiceMock {
    private static readonly DESCRIPTIONS: Description[] = [
        {
            id: 1,
            type: 'INFO',
            title: 'General Description',
            descriptions: [
                {
                    languageCode: 'en',
                    title: 'General Description',
                    description: 'This is a general description.',
                },
                {
                    languageCode: 'de',
                    title: 'Allgemeine Beschreibung',
                    description: 'Dies ist eine allgemeine Beschreibung.',
                },
            ],
            images: [],
        },
        {
            id: 3,
            type: 'INFO',
            title: 'Marketing Description',
            descriptions: [
                {
                    languageCode: 'en',
                    title: 'Marketing Description',
                    description: 'This is a marketing description.',
                },
                {
                    languageCode: 'de',
                    title: 'Marketingbeschreibung',
                    description: 'Dies ist eine Marketingbeschreibung.',
                },
            ],
            images: [],
        },
        {
            id: 3,
            type: 'DETAIL',
            title: 'Property Location',
            descriptions: [
                {
                    languageCode: 'en',
                    title: 'Property Location',
                    description: 'This is a Property Location description.',
                },
                {
                    languageCode: 'de',
                    title: 'Unterkunftsort',
                    description: 'Dies ist eine Beschreibung des Unterkunftsortes.',
                },
            ],
            images: [],
        },
    ];

    getDescriptions(hotelId: string): Observable<Description[]> {
        return of(DescriptionsServiceMock.DESCRIPTIONS);
    }

    getDescription(hotelId: string, type: DescriptionType, id: number): Observable<Description> {
        return of(DescriptionsServiceMock.DESCRIPTIONS
            .find(description => description.id === id && description.type === type));
    }

    updateDescription(hotelId: string, description: Description): Observable<void> {
        return of();
    }
}
