import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointService } from '../endpoint.service';

import { ErpInvoiceInfo } from './interfaces/erp-invoice-info.interface';
import { ErpTicketInformation } from './interfaces/erp-ticket-information.interface';
import { HotelErpServiceInterface } from './interfaces/hotel-erp.service.interface';

@Injectable()
export class HotelErpService extends EndpointService implements HotelErpServiceInterface {
    constructor(http: HttpClient) {
        super(http);
    }

    getErpInfo(hotelId: string): Observable<ErpInvoiceInfo> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/erp/invoiceinfo`;

        return this.httpGet<ErpInvoiceInfo>(url, EndpointService.HTTP_HEADERS);
    }

    createHelpdeskTicket(hotelId: string, ticketInformation: ErpTicketInformation): Observable<string> {
        const url = `${EndpointService.getBmBackendUrl()}/api/cm/api/hotel/${hotelId}/erp/helpdesk`;

        return this.httpPost<string>(url, ticketInformation, EndpointService.HTTP_HEADERS);
    }
}
