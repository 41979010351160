/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CodeTitle } from '@bmng/services/metadata/interfaces/metadata.interface';
import { PolicyDescription, PortalPolicyApplicability, PortalPolicyApplicabilityItem } from '@bmng/services/policies/interfaces/policies.interface';

import { UiCancellationPaymentPolicy, UiCancellationPenalty, UiPolicyCancellation } from '../../../../pages/master/policies/interface/policies-ui.interface';
import { PolicyFormHelpersService } from '../policy-form-helpers.service';
import { PolicyFormValidators } from '../policy-form-validators.service';

import { PolicyFormCancellationValidators } from './policy-form-cancellation-validators.service';

@Injectable()
export class PolicyFormCancellationService {
    constructor(
        private formHelpersService: PolicyFormHelpersService,
        private cancellationValidators: PolicyFormCancellationValidators,
        private validators: PolicyFormValidators,
    ) {}

    getCancellationPolicyForm(
        policyConfig: UiPolicyCancellation,
        isNewPolicy: boolean,
        languages: CodeTitle[],
        usedCodes?: string[],
    ): UntypedFormGroup {
        if (!policyConfig) {
            return;
        }

        return new UntypedFormGroup({
            code: new UntypedFormControl(policyConfig.code, [
                Validators.required,
                this.validators.uniqueCodeValidation(isNewPolicy, usedCodes),
            ]),
            portalId: new UntypedFormControl(policyConfig.portalId),
            enabled: new UntypedFormControl(policyConfig.enabled),
            currencyCode: new UntypedFormControl(policyConfig.currencyCode),
            onlineCancellationByGuest: new UntypedFormControl(policyConfig.onlineCancellationByGuest),
            textOnly: new UntypedFormControl({
                value: policyConfig.textOnly,
                disabled: policyConfig.onlineCancellationByGuest !== 'ForbidOnlineCancel',
            }),
            cancellationRefund: new UntypedFormControl(policyConfig.cancellationRefund),
            uiPaymentPolicies: new UntypedFormArray(policyConfig.uiPaymentPolicies
                .map(uiPaymentPolicy => this.getCancellationPaymentPolicyControls(uiPaymentPolicy, languages, policyConfig.textOnly))),
            uiApplicability: this.getApplicabilityForm(policyConfig.applicability),
            context: new UntypedFormControl(policyConfig.context),
            sort: new UntypedFormControl(policyConfig.sort),
        });
    }

    private getTextOnlyDescriptionControlsByLang(
        descriptions: PolicyDescription[],
        languages: CodeTitle[],
        textOnly: boolean,
    ): UntypedFormArray {
        const textOnlyDescriptions = new UntypedFormArray(
            this.formHelpersService.getDescriptionControlsByLang(
                languages,
                descriptions,
            ),
        );

        textOnlyDescriptions.at(0).get('description').setValidators(Validators.required);

        if (!textOnly) {
            textOnlyDescriptions.disable();
        }

        return textOnlyDescriptions;
    }

    getCancellationPaymentPolicyControls(
        uiPaymentPolicy: UiCancellationPaymentPolicy,
        languages: CodeTitle[],
        textOnly: boolean = false,
    ): UntypedFormGroup {
        const policy: { [key: string ]: UntypedFormControl | UntypedFormArray } = {
            paymentPolicyType: new UntypedFormControl(uiPaymentPolicy.paymentPolicyType),
            uiDateRanges: new UntypedFormControl(uiPaymentPolicy.uiDateRanges),
            textOnlyDescriptions: this.getTextOnlyDescriptionControlsByLang(
                uiPaymentPolicy.textOnlyDescriptions,
                languages,
                textOnly,
            ),
            uiPenalties: new UntypedFormArray((uiPaymentPolicy.uiPenalties || [])
                .map(uiPenalty => this.getCancellationPenaltiesControls(uiPenalty, languages, textOnly))),
        };

        return new UntypedFormGroup(policy, [
            this.cancellationValidators.paymentPolicyLeadTimeAlwaysValidator(),
            this.cancellationValidators.paymentPolicyLeadTimeDaysValidator(),
        ]);
    }

    getCancellationPenaltiesControls(
        uiPenalty: UiCancellationPenalty,
        languages: CodeTitle[],
        textOnly: boolean = false,
    ): UntypedFormGroup {
        return new UntypedFormGroup({
            leadTimeType: new UntypedFormControl({
                value: uiPenalty.leadTimeType,
                disabled: textOnly,
            }),
            leadTimeDays: new UntypedFormControl({
                value: uiPenalty.leadTimeDays,
                disabled: textOnly || uiPenalty.leadTimeType === 'Always',
            }, [
                Validators.required,
                Validators.max(998),
            ]),
            leadTimeOfDay: new UntypedFormControl({
                value: uiPenalty.leadTimeOfDay,
                disabled: textOnly || uiPenalty.leadTimeType === 'Always',
            }),
            hasPercent: new UntypedFormControl(uiPenalty.hasPercent),
            percent: new UntypedFormControl({
                value: uiPenalty.percent,
                disabled: textOnly || !uiPenalty.hasPercent,
            }, [
                Validators.required,
            ]),
            stayType: new UntypedFormControl({
                value: uiPenalty.stayType,
                disabled: !uiPenalty.hasPercent,
            }),
            numberOfNights: new UntypedFormControl({
                value: uiPenalty.numberOfNights,
                disabled: textOnly || !uiPenalty.hasPercent || uiPenalty.stayType === 'FullStay',
            }, [
                Validators.required,
                Validators.min(1),
                Validators.max(99),
            ]),
            hasAmount: new UntypedFormControl(uiPenalty.hasAmount),
            amount: new UntypedFormControl({
                value: uiPenalty.amount,
                disabled: textOnly || !uiPenalty.hasAmount,
            }, [
                Validators.required,
                Validators.max(999999.99),
            ]),
            descriptions: new UntypedFormArray(
                this.formHelpersService.getDescriptionControlsByLang(
                    languages,
                    uiPenalty.descriptions,
                    false,
                ),
            ),
            showDescriptions: new UntypedFormControl(uiPenalty.showDescriptions),
            summary: new UntypedFormControl(uiPenalty.summary),
        });
    }

    updateCancellationPolicySummary(policyConfig: UiPolicyCancellation, form: UntypedFormGroup): void {
        const uiPaymentPolicyCtrls = form.get('uiPaymentPolicies') as UntypedFormArray;
        if (uiPaymentPolicyCtrls && uiPaymentPolicyCtrls.controls) {
            uiPaymentPolicyCtrls.controls.forEach((uiPaymentPolicyCtrl: UntypedFormGroup, paymentPolicyIndex) => {
                this.updateCancellationPaymentPolicySummary(policyConfig, uiPaymentPolicyCtrl, paymentPolicyIndex);
            });
        }
    }

    updateCancellationPaymentPolicySummary(
        policyConfig: UiPolicyCancellation,
        uiPaymentPolicyCtrl: UntypedFormGroup,
        paymentPolicyIndex: number,
    ): void {
        const paymentPolicy = policyConfig.uiPaymentPolicies[paymentPolicyIndex];
        const uiPenaltyCtrls = uiPaymentPolicyCtrl.get('uiPenalties') as UntypedFormArray;
        if (paymentPolicy && uiPenaltyCtrls) {
            uiPenaltyCtrls.controls.forEach((uiPenaltyCtrl: UntypedFormGroup, uiPenaltyIndex) => {
                const penalty = paymentPolicy.uiPenalties[uiPenaltyIndex];
                const summary = penalty ? penalty.summary || '' : '';
                const summaryCtrl = uiPenaltyCtrl.get('summary');
                if (summaryCtrl) {
                    summaryCtrl.setValue(summary);
                }
            });
        }
    }

    private getApplicabilityForm(applicability: PortalPolicyApplicability): UntypedFormGroup {
        return new UntypedFormGroup({
            hotels: new UntypedFormControl(this.getCodesFromPortalHotelsOrSuppliers(applicability?.hotels)),
            suppliers: new UntypedFormControl(this.getCodesFromPortalHotelsOrSuppliers(applicability?.suppliers)),
        });
    }

    private getCodesFromPortalHotelsOrSuppliers(items: PortalPolicyApplicabilityItem[]): string[] {
        return !items ? null : items.map(item => item.code);
    }
}
