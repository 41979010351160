import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EndpointService } from '@bmng/services/endpoint.service';
import { UsersService } from '@bmng/services/users/users.service';
import { LangService } from '@kognitiv/angular-i18n';
import { LocalStorageService } from '@kognitiv/bm-components';
import { timeout } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import { RouteHelpers } from '../../../shared/helpers/route-helpers';
import { RouteTransformerService } from '../routes/route-transformer.service';

import { environment } from './../../../../environments/environment';
import { AutoSubscribingFormAnalyticsSubscription } from './auto-subscribing-form-analytics-subscription.class';
import { AnalyticsContextData } from './interfaces/analytics-context-data.interface';
import { AnalyticsEvent } from './interfaces/analytics-event.interface';
import { AnalyticsLogData } from './interfaces/analytics-log-data.interface';
import {
    AnalyticsServiceInterface,
    FormAnalyticsParams,
    FormAnalyticsSubscription,
} from './interfaces/analytics-service.interface';

const TIME_OF_PAGE_LOAD: Date = new Date();
declare const window: Window;

interface WriteAheadLog<T> {
    entry: AnalyticsEvent<T> | AnalyticsLogData<T>;         // AnalyticsEvent<T> is only kept for bw-compat reasons
    id: string;
}

@Injectable()
export class AnalyticsService extends EndpointService implements AnalyticsServiceInterface {
    private static readonly LOG_STASH_NAME = 'analyticsEventLog';

    constructor(
        http: HttpClient,
        private usersService: UsersService,
        private langService: LangService,
        private route: ActivatedRoute,
        private storageSrv: LocalStorageService,
        private routeTransformerSrv: RouteTransformerService,
    ) {
        super(http);
        this._retryFailedRemoteLogging();
    }

    trackFormChanges<TForm, TTrack>(params: FormAnalyticsParams<TForm, TTrack>): FormAnalyticsSubscription {
        return new AutoSubscribingFormAnalyticsSubscription<TForm, TTrack>(
            params.entityId,
            this,
            params.form,
            params.transform,
        );
    }

    track<T>(analyticsEvent: AnalyticsEvent<T> | AnalyticsLogData<T>, persisting: boolean = true): Promise<boolean> {
        return new Promise((resolve, _reject) => {
            const url = `${environment.baseUrls.quality}/events/hotelManager/${analyticsEvent.collection}`;
            let logData: AnalyticsLogData<T>;

            if (this.isAnalyticsLogData(analyticsEvent)) {
                logData = analyticsEvent;
            } else {
                logData = this.getAnalyticsLogData(analyticsEvent);
            }

            let trackingId = null;

            if (persisting) {
                trackingId = this.persistAsWriteAheadLogEntry(logData);
            }

            this.httpPost<never>(url, logData, EndpointService.HTTP_HEADERS).pipe(
                timeout(3000))
                .subscribe(
                    () => {
                        resolve(true);

                        if (!!trackingId) {
                            this.deleteLogEntry(trackingId);
                        }
                    },
                    error => {
                        // We always want to resolve so we don't block due to faulty error handling
                        // But the status can optionally be fetched from the return code
                        console.log(`Could not send track: ${error}`);
                        resolve(false);
                    },
                );
        });
    }

    private persistAsWriteAheadLogEntry<T>(event: AnalyticsLogData<T>): string {
        const timestamp = (new Date()).getTime();
        const rand = parseInt(`${(Math.random() * 10000)}`, 10);
        const id = `${timestamp}_${rand}`;

        try {
            const entries: WriteAheadLog<unknown>[] = this.storageSrv.getItem(AnalyticsService.LOG_STASH_NAME) || [];
            entries.push({
                entry: event,
                id,
            });
            this.storageSrv.setItem(AnalyticsService.LOG_STASH_NAME, entries);
        } catch (e) {
            console.log('could not persist analytics event', e);
        }

        return id;
    }

    private isAnalyticsLogData<T>(event: AnalyticsEvent<T> | AnalyticsLogData<T>): event is AnalyticsLogData<T> {
        return event.hasOwnProperty('userId');
    }

    private deleteLogEntry(id: string): void {
        try {
            let entries: WriteAheadLog<unknown>[] = this.storageSrv.getItem(AnalyticsService.LOG_STASH_NAME) || [];
            entries = entries.filter(entry => entry.id !== id);
            this.storageSrv.setItem(AnalyticsService.LOG_STASH_NAME, entries);
        } catch (e) {
            console.log('could not remove analytics event from log stash', e);
        }
    }

    _retryFailedRemoteLogging(): void {
        try {
            const entries: WriteAheadLog<unknown>[] = this.storageSrv.getItem(AnalyticsService.LOG_STASH_NAME) || [];

            entries.forEach(entry => {
                this.deleteLogEntry(entry.id);
                this.track(entry.entry);
            });
        } catch (e) {
            console.log('Could not clean up analytics event log stash', e);
        }
    }

    getAnalyticsLogData<T>(event: AnalyticsEvent<T>): AnalyticsLogData<T> {
        const userId = this.usersService.currentUserSettings.value && this.usersService.currentUserSettings.value.id || '';
        const routeData = RouteHelpers.getFullDataFromRoute(this.route.snapshot);
        const paths = this.routeTransformerSrv.getPathPattern();
        const contextParams: AnalyticsContextData = {
            timeFromNavStart: this.getTimeFromNavStartInMs(),
            viewport: this.getViewPort(),
            screenSize: this.getScreenSize(),
            url: window.location.href,
            path: window.location.pathname.substring(1),
            activeNavigation: this.routeTransformerSrv.getActiveNavigation(paths.pathPattern),
            language: this.langService.getCurrentLanguage(),
            eventType: routeData.contextType,
            userRole: this.getUserRole(routeData),
            user_agent_info: '${userAgentInfo}',
            user_agent: '${userAgent}',
            ip_address: '${remoteAddr}',
            geo_info: '${geoInfo}',
            ...paths,
        };

        if (!!event.contextData) {
            Object.keys(event.contextData).forEach(key => {
                contextParams[key] = event.contextData[key];
            });
        }

        return {
            userId: <string> Md5.hashStr(userId),
            hotelId: event.entityId || '',
            contextType: routeData.contextType,
            eventData: event.data,
            data: contextParams,
            collection: event.collection,
        };
    }

    private getUserRole(routeData: Params): string {
        const contextType: string = routeData.contextType;
        const contextData = routeData.context;
        if (contextData) {
            const context = contextData[contextType];
            if (context) {
                return context.role;
            }
        }
    }

    private getScreenSize(): string {
        let screenSize = 'unkown';

        if (window.innerWidth >= 1600) {
            screenSize = 'xxlarge';
        } else if (window.innerWidth >= 1200) {
            screenSize = 'xlarge';
        } else if (window.innerWidth >= 992) {
            screenSize = 'large';
        } else if (window.innerWidth >= 768) {
            screenSize = 'medium';
        } else if (window.innerWidth >= 576) {
            screenSize = 'small';
        } else if (window.innerWidth > 0) {
            screenSize = 'xsmall';
        }

        return screenSize;
    }

    private getViewPort(): number[] {
        return [
            window.innerWidth,
            window.innerHeight,
        ];
    }

    private getTimeFromNavStartInMs(): number {
        const currentTimeInMS = (new Date()).getTime();
        return currentTimeInMS - TIME_OF_PAGE_LOAD.getTime();
    }
}
